import { Provider } from 'react-redux';
import '../styles/globals.scss';
import '../public/assets/include/scss/unify.scss';
import 'bootstrap/dist/css/bootstrap.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import NextNprogress from 'nextjs-progressbar';
import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
import 'reactjs-popup/dist/index.css';
import '../public/css/mapbox-gl.css';

import { useRouter } from 'next/router';
import { useState } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { PandoraProvider } from '@iib/pandora-box';
import store from '../store';
import { MapContext } from '../context/index.ts';
import { MapProvider } from '@iib/pandora-box';

config.autoAddCss = false;

const option = {
  startPosition: 0.3,
  stopDelayMs: 200,
  height: 3,
};
// eslint-disable-next-line react/prop-types
function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const isIframe = router.asPath === '/iframe-opnv';
  const [Map, setMap] = useState();

  return (
    <Provider store={store}>
      {isIframe ? (
        <Component {...pageProps} />
      ) : (
        // eslint-disable-next-line max-len
        <Auth0Provider // Do not render Auth0Provider if iframe page is being used because it will break when it tries to access session storage.
          domain={process.env.AUTH0_DOMAIN || ''}
          clientId={process.env.AUTH0_CLIENT_ID || ''}
          redirectUri={
            typeof window !== 'undefined' && window.location.origin
              ? window.location.origin
              : ''
          }
        >
          <PandoraProvider>
            <MapContext.Provider
              value={{
                Map,
                setMap,
              }}
            >
              <MapProvider>
                <NextNprogress option={option} color="rgb(153,0,51)" />
                <Component {...pageProps} />
              </MapProvider>
            </MapContext.Provider>
          </PandoraProvider>
        </Auth0Provider>
      )}
    </Provider>
  );
}

export default MyApp;
