var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"Iw2AtYD5_rpX9f0EoE9YN"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN,
  beforeSend(event) {
    if (
      event?.request?.url?.match(/_next/gi) || // Suppress error from static/folder
      event?.request?.url?.match(/media/gi) || // Suprress error from static/media/folder
      event?.request?.url?.match(/mapwidget/gi) || // Suppress error maybe related to IE 9
      event?.request?.url?.match(/favicon/gi) || // Suppress error from favicon related to the title
      event?.request?.url?.match(/css/gi) || // Suprress from static/css/folder
      event?.request?.url?.match(/wordpress/gi) || // Suprress error when searchbots look for this
      event?.request?.url?.match(/jetzt-finden/gi) || // Suppress error if someone looks for this
      event?.request?.url?.match(/\.\w+/gi) || // Suppress error if someone looks for /.env /.whatever
      event?.request?.url?.match(/localhost/gi) || // Suppress localhost errors
      event?.transaction?.match(/head/gi)
    ) {
      // supress error which occur in HEAD
      return null;
    }
    return event;
  },
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
